
  import {isHttpUrl} from '@/helper/ImageHelper';
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    mixins: [ScreenSize],
    props: {
      sheetElements: {
        type: Array,
        required: true,
      },
      numberOfElements: {
        type: String,
        required: true,
        default: 'three_3',
      },
    },
    data() {
      return {
        sortedElements: [],
      };
    },
    computed: {
      size() {
        return this.numberOfElements ? 12 / this.columnNumber : 4;
      },
      columnNumber() {
        return Number(this.numberOfElements.split('_')[1]);
      },
    },
    created() {
      const elements = this.sheetElements;
      this.sortedElements = elements.sort((a, b) =>
        a.text.localeCompare(b.text)
      );
    },
    methods: {
      imageUrl(url) {
        return isHttpUrl(url) ? url : require(`@/assets/${url}`);
      },
    },
  };
